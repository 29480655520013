import classNames from 'classnames';
import { observer } from 'mobx-react';
import type { FunctionComponent } from 'react';
import { Section } from '../../components/layout/section';
import { SectionBlock } from '../../components/layout/section-block';
import { SectionVisual } from '../../components/layout/section-visual';
import { List } from '../../components/list';
import { Markdown } from '../../components/markdown';
import { Paragraph } from '../../components/paragraph';
import type { SectionFragment } from '../../graphql/fragments/SectionFragment.graphql';
import { useIsMobile } from '../../lib/hooks/useMedia';
import { usePersonalizations } from '../../lib/hooks/usePersonalizations';
import { reverseSide } from '../../lib/utils/side';
import type { WithTestId } from '../../lib/utils/testid';
import { renderTemplate } from '../../lib/utils/text';
import { ActionButton } from '../action-button';
import { SelfFetchingRichText } from '../rich-text';
import { VideoVisual } from '../video';
import styles from './section-of-content.module.scss';
import { fragmentToVisualProps } from './utils';

// this type of section is everywhere, thus no dynamic import necessary
export const SectionOfContent: FunctionComponent<Props> = observer(({
  sys,
  image,
  video,
  action,
  subtitle,
  content,
  title,
  background,
  loading,
  listWithIcons,
  preset = 'default',
  anchor,
  'data-testid': testId,
  showVideoStandalone = true,
  padHorizontal = true,
  fixedActionButton = false,
  ...restOfProps
}) => {
  const isMobile = useIsMobile();
  const visualProps = fragmentToVisualProps(restOfProps);
  const primary = preset === 'primary';
  const videoIsStandalone = showVideoStandalone && isMobile;
  const personalizations = usePersonalizations();
  const hasVisual = Boolean(image || video || loading);
  const videoNode = video && <VideoVisual {...video} standalone={isMobile && videoIsStandalone} />;
  return <>
                {videoIsStandalone && videoNode}

                <Section data-testid={testId} preset={preset} loading={loading} grid={hasVisual} padHorizontal={padHorizontal}>
                    <SectionBlock className={styles.block} textAlignOnSmallScreen={hasVisual ? 'left' : 'center'} sideOnSmallScreen={reverseSide(visualProps.sideOnSmallScreen)} sideOnLargeScreen={reverseSide(visualProps.sideOnLargeScreen)}>
                        <div className={classNames(styles.box, styles[`box-${preset}`], {
          [styles['box-loading']]: loading
        })}>
                            <Markdown tag={primary ? 'h1' : 'h2'} typeStyle={{
            _: 'heading-md',
            md: 'heading-lg'
          }} markdown={renderTemplate(title, personalizations)} />
                            {!loading && <div className={styles.subtitle} data-testid='section.subtitle'>
                                    {content ? <SelfFetchingRichText {...content} /> : subtitle && <Markdown tag='p' typeStyle='body-10' markdown={renderTemplate(subtitle, personalizations)} color='white' />}
                                </div>}
                            {loading && <Paragraph loading />}
                            {!!listWithIcons?.items?.length && <List withCustomIcons className={styles.list}>
                                    {listWithIcons.items.map(({
              sys: {
                id
              },
              icon: {
                alt = '',
                svgSource = ''
              } = {},
              title
            }) => <List.Item key={id} icon={{
              alt,
              source: svgSource
            }}>
                                                {renderTemplate(title, personalizations)}
                                            </List.Item>)}
                                </List>}

                            {action && 'type' in action && <div className={classNames(styles.action, fixedActionButton && styles['action-fixed'])}>
                                    <ActionButton {...action} placement={primary ? 'banner' : 'section'} containerElementId={anchor} title={renderTemplate(action.title, personalizations)} />
                                </div>}
                        </div>
                    </SectionBlock>

                    {hasVisual && <SectionVisual videoNode={!videoIsStandalone && videoNode} image={!videoNode || !primary ? image : undefined} {...visualProps} preset={preset} loading={loading} />}
                </Section>
            </>;
});
export default SectionOfContent;
type Props = Partial<SectionFragment> & WithTestId<{
  /** Is this the main section on page, or a regular one? */
  preset?: 'primary' | 'default';
  /** Is the content inside loading? */
  loading?: boolean;
  /**
   * Add horizontal padding to the section.
   * @default true
   */
  padHorizontal?: boolean;
}>;