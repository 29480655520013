import type { HorizontalSide, VerticalSide } from '../../types/styles'

export function reverseSide<S extends HorizontalSide | VerticalSide>(
    side: S
): S {
    return {
        top: 'bottom',
        bottom: 'top',
        left: 'right',
        right: 'left',
    }[side] as S
}
